export const toTreeData = (resource) => {
  return createRootNode(resource);
};

export const createRootNode = (resource) => {
  let rootNode = {
    id: "_root",
    name: "_root",
    type: "root",
    data: {},
    children: [],
  };

  rootNode.children = Object.keys(resource).map((key) => createEntityNode(key));

  return rootNode;
};

export const createEntityNode = (name) => {
  let node = {
    id: name,
    name: name,
    type: "entity",
    data: {},
    children: [],
  };
  return node;
};
