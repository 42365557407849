<template>
  <v-container fluid>
    <GsCollectionManager :config="config" />
  </v-container>
</template>

<script>
import { GsCollectionManager } from "ngt-collection-manager-lib";
import { i18n } from "@/locales/i18n";
import { apiOpsBff } from "ngt-frontend-core";

export default {
  name: "CollectionManager",
  components: {
    GsCollectionManager,
  },
  props: {},
  data() {
    return {
      config: null,
    };
  },
  created: async function () {
    this.config = {
      url: `${process.env.VUE_APP_BFF_ORIGIN}/_abac/tag-collection`,
      dataConnector: apiOpsBff.callAPI,
      t: (key) => i18n.t(key),
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
